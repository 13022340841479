.childContainer{
  margin: .5rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.childWrapper{
  height: 100%;
  font-size: 1.4rem;
  padding: 2.3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 2px 4px 12px rgb(0 0 0 / 8%); */
  box-shadow: rgba(221, 158, 193, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.childWrapper *{
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
   -ms-transition: all 1s ease-in-out;
   transition: all 1s ease-in-out;
}
.viewCount{
  font-size: 2.8rem;
  background-image: linear-gradient(45deg, #D4145A, #FBB03B);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bolder;
  position: relative;
}
.viewCount:before, .viewCount:after {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: tomato;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(0);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
}
.viewCount:after {
  animation-delay: 2s;
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
.cardshelf{
  margin-top: 1rem;
  background-color: #f9fbfc9d;
}
.cardshelf__header{
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: black;
}
.cardshelf__headerpara{
  color: #555;
}

.textGradient{
  color: black;
}
@supports (background-clip: text){
  .textGradient {
    background-image: linear-gradient(45deg, #D4145A, #FBB03B);
    color: transparent;
    background-clip: text;
  }
}
.borderGradient {
  --borderWidth: 3px;
  background: #ffff;
  position: relative;
  border-radius: var(--borderWidth);
}
.borderGradient:not(:last-child){
  margin: 1rem 0;
}

.borderGradient::after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
